import { TeaserDataApiResponse } from "@app/types/ServerCustomContext";
import { mapTeaserDataToLatestProps } from "@app/utils/mapTeaserDataToProps";
import MetaData from "@components/Modules/MetaData";
import SearchForm from "@components/Modules/SearchForm/SearchForm";
import PubBaseLayout from "@pub/BaseLayout";
import { configError, configLatestStory, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import { LatestArticle, Typography } from "@sphtech/dmg-design-system";
import { NoSSR } from "@sphtech/web2-core/components";
import { ResponseType, TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import cx from "classnames";
import { useCustomClassContext } from "packages/design-system/src/hooks/useCustomClassContext/CustomClassContext";
import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";

import styles from "./ErrorLayout.module.css";

const { Heading, Body } = Typography;

function RenderLatestArticles() {
  const { data } = useSWR<TeaserDataApiResponse>(
    "/_plat/api/teaser/latest?size=3",
  );
  const customClass = useCustomClassContext();

  return (
    !!data?.results?.length && (
      <div
        className={cx(
          styles.bodyContainer,
          styles.gutterHorizontal,
          styles.relatedContentBlock,
        )}
      >
        <Heading.SubHeading3>
          <div className={cx(customClass?.relatedItem?.verticalLine)}>
            {configError.relatedTitle ||
              "Perhaps you might be interested in these."}
          </div>
        </Heading.SubHeading3>
        <div
          className={cx(styles.content, customClass?.relatedItem?.firstItem)}
        >
          {data.results
            .map((teaser) => mapTeaserDataToLatestProps(teaser))
            .map((data, index) => (
              <LatestArticle
                key={index}
                {...data}
                mode={
                  Array.isArray(configLatestStory.errorLayoutMode)
                    ? configLatestStory.errorLayoutMode[index]
                    : configLatestStory.errorLayoutMode || "portrait"
                }
                portraitImageWidth={configLatestStory.portraitImageWidth}
                landscapeImageWidth={configLatestStory.landscapeImageWidth}
                placeholders={placeholders}
                displayPublishDate={configTeaser.publishDate}
              />
            ))}
        </div>
      </div>
    )
  );
}

const textForStatusCode = {
  400: {
    pageTitle: "Page not available",
    title: "We are sorry, the page you are looking for cannot be served.",
    lead: "Please ensure you are eligible to request this page.",
  },
  404: {
    pageTitle: "Page not found",
    title: "We are sorry, the page you are looking for cannot be found.",
    lead: (
      <>
        Head to our{" "}
        <a href="/">
          <u>home page</u>
        </a>{" "}
        or try searching again.
      </>
    ),
  },
  500: {
    pageTitle: "Unexpected Error",
    title: "Oops, something went wrong.",
    lead: "Try refreshing this page or contact us if the problem persists.",
  },
  clientSideError: {
    pageTitle: "Unexpected Error",
    title: "Oops, something went wrong.",
    lead: "Try refreshing this page, updating your browser or contact us if the problem persists.",
  },
  503: {
    pageTitle: "Service Unavailable",
    title: "Oops, something took too long.",
    lead: "Try refreshing this page after 30 seconds or contact us if the problem persists.",
  },
};

const ErrorLayout = ({
  response,
  clientSideError,
}: {
  response: TRouteWithRedirect<unknown, unknown>;
  clientSideError?: boolean;
}): ReactNode => {
  const statusCode = response.statusCode;
  const text = clientSideError
    ? textForStatusCode.clientSideError
    : statusCode === 400 || statusCode === 404 || statusCode === 503
      ? textForStatusCode[statusCode]
      : textForStatusCode[500];

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (response.type === ResponseType.REDIRECT) {
      navigate(response.target);
      return;
    }
  }, [response, navigate]);

  if (response.type === ResponseType.REDIRECT) {
    return (
      <PubBaseLayout ads={false}>
        <MetaData
          title={text.pageTitle}
          type="WebPage"
          path={location.pathname}
        />
        <div
          className={cx(
            styles.bodyContainer,
            styles.gutterHorizontal,
            styles.gutterVertical,
          )}
        >
          <div className={styles.notFoundTopContainer}>
            <Heading.Title>You are being redirected</Heading.Title>
            <Body>
              <a href={response.target}>
                <u>Please click here if your browser does not redirect you.</u>
              </a>
            </Body>
          </div>
        </div>
      </PubBaseLayout>
    );
  }

  return (
    <PubBaseLayout ads={false}>
      <MetaData
        title={text.pageTitle}
        type="WebPage"
        path={location.pathname}
      />
      <div
        className={cx(
          styles.gutterHorizontal,
          styles.gutterVertical,
          styles.notFoundTopContainerBackground,
        )}
      >
        <div className={cx(styles.bodyContainer, styles.notFoundTopContainer)}>
          <Heading.Title>{text.title}</Heading.Title>

          <Body>{text.lead}</Body>
          {statusCode === 404 && (
            <div className={styles.searchInputContainer}>
              <SearchForm
                value=""
                onSubmit={(fieldValue) => {
                  window.location.assign(
                    `/search/?q=${encodeURIComponent(fieldValue)}`,
                  );
                }}
              />
            </div>
          )}
        </div>
      </div>
      {statusCode === 404 && (
        <NoSSR>
          <RenderLatestArticles />
        </NoSSR>
      )}
    </PubBaseLayout>
  );
};

export default ErrorLayout;
