import { ReactElement, ReactNode, useEffect } from "react";
import Button from "../../Element/Button";
import ResponsiveImage from "../../Element/ResponsiveImage";
import { ImageCrop } from "../../Utility/dataTypes";
import styles from "./index.module.css";
import cx from "classnames";

type Props = {
  id: string;
  name: string;
  header: string;
  image?: ImageCrop;
  discount?: string;
  dealsSource: {
    name: string;
    url: string;
    discount: string;
    discountPrice: string;
  }[];
  pros?: ReactNode[];
  cons?: ReactNode[];
  detailed?: boolean;
};

const ProsIcon = (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.27317 13L12.3251 16.3766L17.7277 9.62333M13.0004 22.4545C11.7589 22.4545 10.5294 22.21 9.38235 21.7348C8.23527 21.2597 7.19301 20.5633 6.31507 19.6853C5.43713 18.8074 4.74072 17.7651 4.26558 16.6181C3.79045 15.471 3.5459 14.2415 3.5459 13C3.5459 11.7584 3.79045 10.5289 4.26558 9.38186C4.74072 8.23478 5.43713 7.19252 6.31507 6.31458C7.19301 5.43665 8.23527 4.74023 9.38235 4.26509C10.5294 3.78996 11.7589 3.54541 13.0004 3.54541C15.5079 3.54541 17.9127 4.54151 19.6858 6.31458C21.4589 8.08765 22.455 10.4925 22.455 13C22.455 15.5075 21.4589 17.9123 19.6858 19.6853C17.9127 21.4584 15.5079 22.4545 13.0004 22.4545Z"
      stroke="#578AA4"
      stroke-width="2"
    />
  </svg>
);

const ConsIcon = (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0004 22.4545C11.7589 22.4545 10.5294 22.21 9.38235 21.7348C8.23527 21.2597 7.19301 20.5633 6.31507 19.6853C5.43713 18.8074 4.74072 17.7651 4.26558 16.6181C3.79045 15.471 3.5459 14.2415 3.5459 13C3.5459 11.7584 3.79045 10.5289 4.26558 9.38186C4.74072 8.23478 5.43713 7.19252 6.31507 6.31458C7.19301 5.43665 8.23527 4.74023 9.38235 4.26509C10.5294 3.78996 11.7589 3.54541 13.0004 3.54541C15.5079 3.54541 17.9127 4.54151 19.6858 6.31458C21.4589 8.08765 22.455 10.4925 22.455 13C22.455 15.5075 21.4589 17.9123 19.6858 19.6853C17.9127 21.4584 15.5079 22.4545 13.0004 22.4545Z"
      stroke="#F37053"
      stroke-width="2"
    />
    <path
      d="M10 16L13 13L16 16M16 10L12.9994 13L10 10"
      stroke="#F37053"
      stroke-width="2"
      stroke-linecap="square"
    />
  </svg>
);

export default function ProductContent({
  id,
  name,
  header,
  image,
  discount,
  dealsSource,
  detailed = false,
  pros,
  cons,
}: Props): ReactElement {
  useEffect(() => {
    if (window.location.hash) {
      goToItem(window.location.hash);
    }
  }, []);

  const goToProductHandler = (e: React.MouseEvent) => {
    if (e.metaKey || e.ctrlKey || e.shiftKey) {
      return;
    }

    e.preventDefault();
    goToItem(e.currentTarget.getAttribute("href"));
  };

  function goToItem(hash: string | null) {
    const scrollElem = hash && document.querySelector(hash);

    if (scrollElem) {
      scrollElem?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }

  return (
    <div className={cx(styles.container, detailed && styles.detailed)}>
      <a
        id={detailed ? `product-${id}` : undefined}
        href={detailed ? undefined : `#product-${id}`}
        onClick={detailed ? undefined : (e) => goToProductHandler(e)}
        className={cx(styles.image, detailed && styles.detailed)}
      >
        {image && (
          <ResponsiveImage
            {...image}
            fill="contain"
            fillPosition="center top"
          />
        )}
      </a>
      <div className={styles.content}>
        {detailed ? (
          <>
            <div className={styles.header}>{header}</div>
            <div className={styles.name}>{name}</div>
          </>
        ) : (
          <a href={`#product-${id}`} onClick={(e) => goToProductHandler(e)}>
            <div className={styles.header}>{header}</div>
            <div className={styles.name}>{name}</div>
            <div className={styles.readmore}>Read more</div>
          </a>
        )}
      </div>
      {discount && <div className={styles.discount}>{discount}</div>}
      <div className={styles.deals}>
        {dealsSource.map((deal, index) => (
          <Button variant="secondary" key={index} href={deal.url}>
            {deal.discountPrice} AT {deal.name}
          </Button>
        ))}
      </div>
      {detailed ? (
        <div className={styles.reviews}>
          {pros && (
            <div className={styles.pros}>
              <p>
                <span>{ProsIcon}</span> PROS
              </p>
              <ul>
                {pros.map((text) => (
                  <li>{text}</li>
                ))}
              </ul>
            </div>
          )}
          {cons && (
            <div className={styles.cons}>
              <p>
                <span>{ConsIcon}</span> CONS
              </p>
              <ul>
                {cons.map((text) => (
                  <li>{text}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}
