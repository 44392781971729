import { ReactElement } from "react";
import styles from "./anchorblock.module.css";
import ResponsiveImage from "../../Element/ResponsiveImage";
import { placeholderCrops } from "../../Utility/placeholder";
import { DateTime } from "../../Element/Typography";
import cx from "classnames";
import BreadCrumbs from "../../Element/BreadCrumb";
import { SummaryItemLarge } from "../../Block/SummaryItem/SummaryItem";
import { BaseTeaserData, ImageCrop } from "../../Utility/dataTypes";
import Divider from "../../Element/Divider/divider";
import { useCustomClassContext } from "../../../hooks/useCustomClassContext/CustomClassContext";

export type AnchorBlockProps = BaseTeaserData & {
  loading?: "lazy" | "eager";
  displayType?: "row" | "column";
  placeholder?: ImageCrop;
  displayPublishDate?: boolean;
  CustomHeadingElement?: React.ComponentProps<
    typeof SummaryItemLarge
  >["CustomHeadingElement"];
  hideBreadCrumb?: boolean;
  mobileCrop?: "square_30_26" | "landscape";
};

/**
 * This is our main Anchor-block component
 */
function AnchorBlock({
  publishDate,
  title,
  path,
  sections,
  image,
  loading,
  displayType = "column",
  placeholder = placeholderCrops.landscape,
  displayPublishDate = true,
  CustomHeadingElement,
  hideBreadCrumb: hideBreadCrumb,
  mobileCrop = "square_30_26",
}: AnchorBlockProps): ReactElement {
  const customClass = useCustomClassContext();
  const isDisplayTypeRow = displayType === "row";
  const dateBeforeTitle = !isDisplayTypeRow;

  const beforeTitle = !hideBreadCrumb && (
    <BreadCrumbs
      sections={sections}
      dateElement={
        displayPublishDate &&
        dateBeforeTitle &&
        publishDate && (
          <DateTime date={new Date(publishDate)} format="dd mname yyyy" />
        )
      }
    />
  );

  return (
    <section
      className={cx(
        styles.anchorBlock,
        isDisplayTypeRow && styles.displayRow,
        customClass?.anchorBlock?.verticalLine,
      )}
    >
      <div className={styles.imageContainer}>
        <a href={path} title={title}>
          <ResponsiveImage
            displayWidth={1000}
            loading={loading}
            {...(image?.crops[mobileCrop] ||
              image?.crops.landscape ||
              placeholder)}
            mobileUp={image?.crops.landscape}
          />
        </a>
      </div>

      <div
        className={cx(
          styles.summaryItemWrapper,
          customClass?.anchorBlock?.summaryItem,
        )}
      >
        <SummaryItemLarge
          beforeTitle={beforeTitle}
          title={title}
          url={path}
          publishedDate={!dateBeforeTitle ? publishDate : undefined}
          CustomHeadingElement={CustomHeadingElement}
        />
        <div className={styles.summaryItemDivider}>
          <Divider variant="secondary" />
        </div>
      </div>
    </section>
  );
}

export default AnchorBlock;
