//import modules from design system
import { ExploreMore } from "@sphtech/dmg-design-system";
import { TeaserData } from "@src/app/types/ServerCustomContext";
import { ReactNode } from "react";

import CategoryLayout from "../Category/CategoryLayout";

type Props = {
  data: {
    name: string;
    latestArticle?: TeaserData[];
    exploreMore?: React.ComponentProps<typeof ExploreMore>;
    mainContentBlock?: TeaserData[];
    lb1?: ReactNode;
    imu1?: ReactNode;
    imu2?: ReactNode;
    showVerticalLine?: boolean;
  };
};

function LatestStoryLayout({ data }: Props): ReactNode {
  return <CategoryLayout data={data} />;
}

export default LatestStoryLayout;
