import { ReactNode, PropsWithChildren } from "react";
import { Divider, ImageCrop, ResponsiveImage, Typography } from "../../../main";
import cx from "classnames";
import styles from "./sponsorshipbox.module.css";
import { TextLink } from "../../Element/Typography";
const { Heading, Body } = Typography;
const { SubHeading2 } = Heading;

const ctaIcon = (
  <svg
    width="9"
    height="15"
    viewBox="0 0 9 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 13.668L7.5 7.66797L1.5 1.66797"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default function sponsorshipBox({
  title,
  description,
  image,
  ctaUrl,
  ctaText,
}: PropsWithChildren<{
  title?: ReactNode;
  description?: ReactNode;
  image?: ImageCrop;
  ctaUrl?: string;
  ctaText?: string;
}>): ReactNode {
  return (
    <>
      <Divider />
      <div className={cx(styles.sponsorshipBoxContainer)}>
        {image && ( // Check if image exists before rendering
          <div className={styles.imageContainer}>
            <ResponsiveImage {...image} displayWidth={165} />
          </div>
        )}
        <div className={styles.content}>
          <SubHeading2>{title}</SubHeading2>
          <div className={styles.description}>
            <Body>{description}</Body>
          </div>

          {/* remove bullet support first */}
          {/* <Body>
                <ul className={styles.bullets}>
                  {bullets?.map((bullet, index) => (
                    <li key={index}>{bullet}</li>
                  ))}
                </ul>
              </Body> */}

          {ctaUrl && (
            <TextLink href={ctaUrl}>
              {ctaText}
              {ctaIcon}
            </TextLink>
          )}
        </div>
      </div>
      <Divider />
    </>
  );
}
