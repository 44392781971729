import { configHome } from "@pub/config";
import AppNewsletter from "@src/app/components/Modules/Newsletter/AppNewsletter";
import cx from "classnames";
import { ReactElement } from "react";

import styles from "../homelayout.module.css";

export default function HomeNewsletter(): ReactElement {
  const newsLetterProps = {
    backgroundImage: configHome.newsletter?.containerImageBg || "",
    newsletterCtaText: "SIGN UP",
    emailPlaceHolder: {
      placeholder: "EMAIL ADDRESS",
    },
    newsletterTitle: configHome.newsletter?.newsletterTitle || "",
    newsletterBody: configHome.newsletter?.newsletterBody || "",
  };

  return (
    <div className={styles.newsletterOuterContainer}>
      <div className={cx(styles.bodyContainer, styles.gutter)}>
        <div className={styles.newsLetterContainer}>
          <AppNewsletter {...newsLetterProps} />
        </div>
      </div>
    </div>
  );
}
