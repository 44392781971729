import "@src/app/types/react.d";

import { PUBLIC_PREVIEW_TEXT, TYPE_FEATURE_STORY } from "@app/constants";
import { StoryData } from "@app/types/ServerCustomContext";
import { AnalyticsArticle } from "@app/utils/AnalyticsSettings";
import { getEmbedUri } from "@app/utils/embed";
import { useInView } from "@components/hooks/hook";
import AppExploreMore from "@components/Modules/AppExploreMore/AppExploreMore";
import OutbrainScript from "@components/Modules/Outbrain/OutbrainScript";
import {
  configExploreMore,
  configSingleStory,
  configSite,
  configTag,
} from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Ads,
  ArticleMastHead,
  BreadCrumbs,
  Button,
  Divider,
  PageShare,
  ResponsiveImage,
  TagsButton,
  Typography,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import React, { useEffect, useState } from "react";

import { Deals } from "./Deals";
import styles from "./SinglePostLayout.module.css";
import Storyline from "./Storyline";
import { Annotation } from "./StorylineElements/Annotation/Annotation";
import Sponsorship from "./StorylineElements/Sponsorship";

const { DateTime, Heading, DateLine } = Typography;

export type SinglePostProps = {
  storyData: StoryData;
  hideContent?: boolean;
  onInView: (storyData: StoryData, inView: boolean) => void;
};

function SinglePostLayout({
  storyData,
  hideContent = false,
  onInView,
}: SinglePostProps) {
  const { configMasthead, configShareArticle } = configSingleStory;
  const [hide, setHide] = useState(hideContent);

  const { ref, inView } = useInView({
    threshold: 0,
  });
  useEffect(() => {
    onInView(storyData, inView);
  }, [inView, storyData, onInView]);
  const { ref: exploreMoreRef, inView: exploreMoreInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const {
    id,
    tags,
    typeName,
    sections,
    byline,
    authors,
    processedElements,
    sponsorship,
    displaySetting,
  } = storyData;

  const {
    titleElement,
    standfirstElement,
    bodyElements,
    coverImage,
    videoElement,
  } = processedElements;

  const isFeatureStory = typeName === TYPE_FEATURE_STORY;

  const mastheadProps = !isFeatureStory && {
    video: videoElement && {
      url: getEmbedUri(videoElement),
    },
    image: coverImage?.crops.original,
  };

  const getAuthorText = () => {
    const hideAuthorFlag = storyData.displaySetting?.hideAuthorFlag;

    if (hideAuthorFlag) {
      return null;
    } else if (byline) {
      return `By ${byline} ${configMasthead.bylineSeparator || "-"} `;
    } else if (authors && authors.length > 0) {
      return (
        <>
          By&nbsp;
          {authors
            .map<React.ReactNode>((author, index) =>
              author.profile?.urlPath ? (
                <a
                  href={author.profile.urlPath}
                  key={index}
                  className={styles.authorLink}
                >
                  {author.name}
                </a>
              ) : (
                author.name
              ),
            )
            .reduce((prev, curr) => [prev, " and ", curr])}
          &nbsp;-&nbsp;
        </>
      );
    }
    return null;
  };

  const bylineAndDate = (
    <DateLine>
      {getAuthorText()}
      {storyData.publishDate && (
        <>
          {configMasthead.prefixForDateLine}
          <DateTime
            date={new Date(storyData.publishDate)}
            format="dd mname yyyy"
          />
        </>
      )}
    </DateLine>
  );

  // Special case for cover caption, based on https://sph.atlassian.net/browse/DMG-3574
  const coverImageCaption =
    coverImage?.title || coverImage?.credit || coverImage?.caption;

  const coverImageCrop =
    coverImage?.crops.square_30_26 || coverImage?.crops.original;

  const bodyMaxWidth = isFeatureStory
    ? (configSingleStory.featureStory?.bodyMaxWidth ?? 1000)
    : (configSingleStory.bodyMaxWidth ?? 655);

  return (
    <article className={styles.articleContainer}>
      <div ref={ref} className={styles.articleTracker}></div>
      {isFeatureStory && (
        <figure className={styles.featureStoryImageCover}>
          {coverImageCrop && (
            <ResponsiveImage
              {...coverImageCrop}
              displayWidth={1000}
              mobileUp={
                coverImage.crops.original && {
                  ...coverImage.crops.original,
                  displayWidth: 1920,
                }
              }
            />
          )}
          {!configSingleStory.featureStory?.skipCoverCaption &&
            coverImageCaption && (
              <figcaption
                className={cx(
                  styles.bodyContainer,
                  styles.featureStoryImageCoverCaption,
                )}
              >
                <Typography.Disclaimer>
                  {coverImageCaption}
                </Typography.Disclaimer>
              </figcaption>
            )}
        </figure>
      )}
      <div
        style={{
          "--custom-body-max-width": `${bodyMaxWidth}px`,
        }}
        className={cx(
          styles.bodyContainer,
          isFeatureStory && styles.featureStory,
        )}
      >
        {!isFeatureStory && <Ads.AdsMidContent />}

        {displaySetting?.publicPreview && (
          <div className={cx(styles.previewMode, styles.gutter)}>
            {PUBLIC_PREVIEW_TEXT}
          </div>
        )}

        <div className={styles.articlmasthead}>
          <ArticleMastHead
            {...mastheadProps}
            articleHeading={
              titleElement && <Annotation element={titleElement} />
            }
            divider={configSingleStory.topDivider}
            category={
              <BreadCrumbs
                sections={sections}
                options={{ depth: configSite.sectionLevel3 ? 3 : 2 }}
              />
            }
            excerpt={
              standfirstElement?.value && (
                <Annotation element={standfirstElement} />
              )
            }
            isFeatured={isFeatureStory}
            placeholder={placeholders.landscape}
            afterDescription={configMasthead.byline && bylineAndDate}
            coverImageCaption={coverImageCaption}
          />
        </div>
        {!!tags.length && configSingleStory.displayTags?.displayTopTags && (
          <div className={cx(styles.tagsContainer, styles.gutter)}>
            {configSingleStory.displayTags.displayTagHeading && (
              <Heading.SubHeading3>Tags</Heading.SubHeading3>
            )}

            <div className={styles.tagsItem}>
              {tags.map((tag, index) => {
                const tagButton = (
                  <TagsButton key={index} href={tag.path}>
                    {tag.name}
                  </TagsButton>
                );

                return configTag.itemSeparator && index !== tags.length - 1 ? (
                  <span key={index}>
                    {tagButton}
                    {configTag.itemSeparator}
                  </span>
                ) : (
                  tagButton
                );
              })}
            </div>
          </div>
        )}
        <div
          className={cx(
            styles.pageShareSingleContainer,
            styles.pageShareSingleContainerTop,
          )}
        >
          <PageShare
            label={
              configMasthead.byline ? configShareArticle.label : bylineAndDate
            }
            url={`${configSite.address}${storyData.path}`}
            title={titleElement?.value}
            size={configSite.pageShare?.size}
            media={configSite.pageShare?.media}
          />
        </div>
        {!hide && (
          <div className={styles.sidebarContainer}>
            <div className={cx(styles.body, styles.gutter)}>
              <Storyline
                elements={bodyElements}
                path={storyData.path}
                typeName={typeName}
                bodyMaxWidth={bodyMaxWidth}
              />

              <Sponsorship sponsorship={sponsorship} typeName={typeName} />

              {!!sections.length && (
                <Deals sectionUniqueName={sections[0].uniqueName} />
              )}

              {!!tags.length && (
                <div
                  className={cx(
                    styles.tagsContainer,
                    styles.tagsContainerBottom,
                  )}
                >
                  {configSingleStory.displayTags?.displayTagHeading && (
                    <Heading.SubHeading3>Tags</Heading.SubHeading3>
                  )}

                  <div className={styles.tagsItem}>
                    {tags.map((tag, index) => {
                      const tagButton = (
                        <TagsButton key={index} href={tag.path}>
                          {tag.name}
                        </TagsButton>
                      );

                      return configTag.itemSeparator &&
                        index !== tags.length - 1 ? (
                        <span key={index}>
                          {tagButton}
                          {configTag.itemSeparator}
                        </span>
                      ) : (
                        tagButton
                      );
                    })}
                  </div>
                </div>
              )}

              {configShareArticle.divider.top && (
                <div className={styles.dividerSpacing}>
                  <Divider />
                </div>
              )}

              <div className={styles.pageShareSingleContainer}>
                <PageShare
                  label={
                    configShareArticle.label
                      ? configShareArticle.label
                      : "Share this article"
                  }
                  url={`${configSite.address}${storyData.path}`}
                  title={titleElement?.value}
                  size={configSite.pageShare?.size}
                  media={configSite.pageShare?.media}
                />
              </div>
              <OutbrainScript url={`${configSite.address}${storyData.path}`} />
            </div>
            <div
              className={styles.sidebar}
              style={{
                display: !isFeatureStory ? undefined : "none",
              }}
            >
              <Ads.AdsSideBar />
              <div className={styles.exploreMore} ref={exploreMoreRef}>
                {!!sections.length && exploreMoreInView && (
                  <AppExploreMore
                    uniqSectionName={sections[0].uniqueName}
                    ignoreIds={id}
                    headingText={configExploreMore.heading}
                  />
                )}
              </div>
              <Ads.AdsSideBar />
            </div>
          </div>
        )}
        {hide ? (
          <div className={styles.readMoreBlock}>
            <Button
              variant="secondary"
              href={storyData.path}
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey || e.shiftKey) {
                  return;
                }
                e.preventDefault();
                const jsondata = JSON.stringify({
                  type: "event",
                  category: "Content-Engagement",
                  action: "click",
                  label: "Read More",
                  other: { path: storyData.path },
                });
                window.SPHMGTM.cmd.push(`send=${jsondata}`);
                setHide(false);
              }}
            >
              Read More
            </Button>
          </div>
        ) : null}
      </div>
      <AnalyticsArticle StoryData={storyData} />
    </article>
  );
}

export default SinglePostLayout;
