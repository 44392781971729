import Button from "../../Element/Button";
import Divider from "../../Element/Divider/divider";
import Icon from "../../Element/Icon/icon";
import { ImageCrop } from "../../Utility/dataTypes";
import ResponsiveImage from "../../Element/ResponsiveImage";
import SocialIcons, {
  SocialIconProps,
} from "../../Block/SocialIcon/SocialIcon";
import * as Typography from "../../Element/Typography";

import cx from "classnames";
import { ReactElement, ReactNode } from "react";

import styles from "./megamenu.module.css";

const { Body } = Typography;

type Items = { text: string; url: string };

type MegaMenuFooterTypes = {
  text: string;
  url: string;
  target?: "_blank";
};

type MegaMenuItemType = {
  text: string;
  url: string;
  placement: "left" | "right";
  subMenu: Items[];
};

type CloseButtonProps = {
  type: "text" | "icon";
  placement: "left" | "right";
  TextOrIcon: ReactNode;
};

type SubscribeboxProps = {
  image?: ImageCrop;
  description: string;
  buttonText: string;
  buttonIcon: ReactNode;
  url: string;
};

type MegaMenuNewsLetterProps = {
  text: string;
  url: string;
  buttonText: string;
};

export type MegaMenuProps = {
  isOpen: boolean;
  toggleSidebar: () => void;
  SubscribeBoxProps?: SubscribeboxProps;
  NewsLetterProps?: MegaMenuNewsLetterProps;
  FooterItemsProps: MegaMenuFooterTypes[];
  MegaMenuItemsProps: MegaMenuItemType[];
  MegaMenuType: "doubleColumn" | "singleColumn";
  CloseButtonProps?: CloseButtonProps;
  socialIconProps?: SocialIconProps;
  divider?: boolean;
};

function RenderMegaMenuDoubleColumnItems({
  MegaMenuItems,
}: {
  MegaMenuItems: MegaMenuItemType[];
}) {
  const [LeftItems, RightItems] = MegaMenuItems.reduce<
    [ReactElement[], ReactElement[]]
  >(
    (acc, item, index) => {
      const Items = (
        <div key={index} className={styles.MegaMenuColItem}>
          <a
            href={item.url}
            className={styles.MegaMenuColTitle}
            data-layout-position="megamenuitem"
          >
            {item.text}
          </a>
          {item.subMenu.map((item, id: number) => (
            <a
              key={id}
              href={item.url}
              className={styles.MegaMenuColSubItem}
              data-layout-position="megamenuitem"
            >
              <Body>{item.text}</Body>
            </a>
          ))}
        </div>
      );

      item.placement === "left" ? acc[0].push(Items) : acc[1].push(Items);
      return acc;
    },
    [[], []],
  );
  return (
    <div className={styles.MegaMenuDoubleColItemsContainer}>
      <div className={styles.MegaMenuItemsleft}>{LeftItems}</div>
      <div className={styles.MegaMenuItemsright}>{RightItems}</div>
    </div>
  );
}

export default function MegaMenu({
  isOpen,
  toggleSidebar,
  CloseButtonProps,
  SubscribeBoxProps,
  NewsLetterProps,
  FooterItemsProps,
  MegaMenuItemsProps,
  MegaMenuType,
  socialIconProps,
  divider = true,
}: MegaMenuProps) {
  return (
    <div
      data-test-id="mega-menu-container"
      className={cx(
        styles.MegaMenuCotainer,
        // styles.isClose,
        isOpen ? styles.isOpen : styles.hide,
      )}
      data-layout-position="megamenu"
    >
      {/* Close Button */}
      <div className={styles.closeButtonContainer}>
        <button onClick={toggleSidebar}>
          {CloseButtonProps?.type === "icon" ? (
            <Icon>{CloseButtonProps.TextOrIcon}</Icon>
          ) : (
            <div className={styles.closeButtonText}>
              <Body>{CloseButtonProps?.TextOrIcon || "Close"}</Body>
            </div>
          )}
        </button>
      </div>
      {/* Menu Items */}
      {MegaMenuType === "doubleColumn" && (
        <RenderMegaMenuDoubleColumnItems MegaMenuItems={MegaMenuItemsProps} />
      )}
      {/* Subscribe box */}
      {SubscribeBoxProps && (
        <>
          <Divider />
          <div
            className={styles.subscribeBoxContainer}
            data-layout-position="megamenublock"
          >
            {SubscribeBoxProps.image && (
              <div className={styles.subscribeBoxImage} style={{ width: 98 }}>
                <ResponsiveImage
                  {...SubscribeBoxProps.image}
                  displayWidth={98}
                  loading="lazy"
                />
              </div>
            )}
            <div>
              <Body>{SubscribeBoxProps.description}</Body>
              <div className={styles.subscribeBoxButton}>
                <Button
                  href={SubscribeBoxProps.url}
                  variant="primary"
                  icon={<>{SubscribeBoxProps.buttonIcon}</>}
                  iconPlacement="after"
                >
                  {SubscribeBoxProps.buttonText}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      {divider && <Divider />}
      {/* NewsLetterbox */}
      {NewsLetterProps && (
        <div
          className={styles.newsletterContainer}
          data-layout-position="megamenublock"
        >
          <Body>{NewsLetterProps.text}</Body>
          <div className={styles.newsLetterButton}>
            <Button href={NewsLetterProps.url} variant="secondary">
              {NewsLetterProps.buttonText}
            </Button>
          </div>
        </div>
      )}

      {/* Footer Items  */}
      <div className={cx(styles.footerContainer)}>
        <div className={cx(styles.MegaMenuFooterItem)}>
          {FooterItemsProps.map((item, index: number) => (
            <Body key={index}>
              <a href={item.url} target={item.target}>
                {item.text}
              </a>
            </Body>
          ))}
        </div>
      </div>
      {/* Social Icons */}
      {!!socialIconProps && (
        <div className={cx(styles.socialIconsContainer)}>
          <SocialIcons {...socialIconProps} />
        </div>
      )}
    </div>
  );
}
