import { AuthorProfile, Divider } from "@sphtech/dmg-design-system";
import {
  AuthorProfileData,
  TeaserDataApiResponse,
} from "@src/app/types/ServerCustomContext";
import AdSettings from "@src/app/utils/AdSettings";
import { FetchError } from "@src/app/utils/swr";
import cx from "classnames";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import useSWRInfinite from "swr/infinite";

import styles from "./authorprofilelayout.module.css";
import AuthorProfileTeaserLayout from "./AuthorProfileTeaserLayout";

type Props = {
  context: AuthorProfileData;
};

function AuthorProfileLayout({ context }: Props): ReactNode {
  const location = useLocation();
  const getLatestStoryByAuthorUrl = (
    _: number,
    prev?: TeaserDataApiResponse,
  ) => {
    if (!context.id) return;
    const params = [
      `id=${context.id}`,
      prev?.endCursor && `after=${encodeURIComponent(prev.endCursor)}`,
    ]
      .filter(Boolean)
      .join("&");
    return `/_plat/api/teaser/by_author?${params}`;
  };

  const { data, size, setSize } = useSWRInfinite<
    TeaserDataApiResponse,
    FetchError
  >(getLatestStoryByAuthorUrl, {
    revalidateFirstPage: false,
    initialSize: 1,
  });

  const hasMoreData = !!data?.[size - 1]?.endCursor;
  const isLoadingMore = size > 1 && typeof data?.[size - 1] === "undefined";

  const loadMore = hasMoreData
    ? () => {
        void setSize((size) => (size += 1));
      }
    : undefined;

  return (
    <div className={cx(styles.bodyContainer, styles.gutter)}>
      <div className={styles.details}>
        <AuthorProfile {...context} lineClamp={false} h1 />
      </div>
      {!!data?.[0]?.results?.length && (
        <>
          <Divider />
          <AdSettings
            pageType="author"
            sectionName="Author"
            adUnits="lb1,lb2,imu1,imu2,catfish,prestitial"
            pageTitle="Author"
            currentLocation={location}
          />
        </>
      )}
      {data?.map((data, index, all) => {
        const isLast = all.length - 1 === index;
        if (!data?.results?.length) {
          return;
        }
        return (
          <AuthorProfileTeaserLayout
            key={index}
            data={data.results}
            loadMore={isLast ? loadMore : undefined}
            isLoadingMore={isLast && isLoadingMore}
          />
        );
      })}
    </div>
  );
}

export default AuthorProfileLayout;
