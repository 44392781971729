import { configLatestStory, configSearch, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import { Ads, LatestArticle, Loader } from "@sphtech/dmg-design-system";
import {} from "@sphtech/dmg-design-system";
import { useInView } from "@src/app/components/hooks/hook";
import { TeaserData } from "@src/app/types/ServerCustomContext";
import { LOAD_MORE_TEXT } from "@src/pubs/common/config";
import cx from "classnames";
import { ReactNode, useEffect } from "react";

import styles from "./authorprofilelayout.module.css";

type Props = {
  data?: TeaserData[];
  isLoadingMore: boolean;
  loadMore?: () => void;
};

function AuthorProfileTeaserLayout({
  data,
  isLoadingMore,
  loadMore,
}: Props): ReactNode {
  const { ref, inView } = useInView({ threshold: 0.1 });
  useEffect(() => {
    if (inView && loadMore) {
      loadMore();
    }
  }, [inView, loadMore]);

  return (
    <>
      {!!data?.length && (
        <section className={styles.section} ref={ref}>
          <div className={styles.mainContentBlockContainer}>
            <div className={styles.mainContentBlockLayout}>
              {data.map((teaser, index) => (
                <LatestArticle
                  key={index}
                  {...teaser}
                  imageSize="small"
                  mode={configLatestStory.mainContentBlockMode}
                  placeholders={placeholders}
                  displayPublishDate={configTeaser.publishDate}
                />
              ))}
            </div>
            <div className={styles.sidebar}>
              <Ads.AdsSideBar />
            </div>
          </div>
        </section>
      )}
      {(loadMore || isLoadingMore) && (
        <div
          ref={ref}
          className={cx(styles.bodyContainer, styles.gutterBottom)}
        >
          {isLoadingMore && (
            <Loader label={configSearch.loadMoreText || LOAD_MORE_TEXT} />
          )}
        </div>
      )}
    </>
  );
}

export default AuthorProfileTeaserLayout;
