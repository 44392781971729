import {
  AFFILIATE_DECLATION_DEFAULT_TEXT,
  SHOW_DEBUG_INFO,
} from "@app/constants";
import { getEmbedUri } from "@app/utils/embed";
import { configAffiliatesDeclaration, configGAM } from "@pub/config";
import {
  Ads,
  CallOnQuote,
  Divider,
  ResponsiveIframe,
  SponsorshipBox,
  Typography,
} from "@sphtech/dmg-design-system";
import ProductContent from "@src/app/components/Modules/Product/ProductContentElement";
import ProductIndex from "@src/app/components/Modules/Product/ProductIndex";
import { isBrandedContent } from "@src/app/utils/sponsored";
import { Fragment } from "react";

import {
  isCUEImageElement,
  ResolvedAffiliateDeclarationElement,
  ResolvedCUEElement,
  resolveImageCrop,
  ResolveSponsorshipBoxInfoElement,
} from "./elements";
import styles from "./Storyline.module.css";
import { Annotation } from "./StorylineElements/Annotation/Annotation";
import CallToAction from "./StorylineElements/CallToAction";
import { ColumnImage } from "./StorylineElements/ColumnImage";
import CustomHtml from "./StorylineElements/CustomHtml";
import Gallery from "./StorylineElements/Gallery/Gallery";
import StorylineImage from "./StorylineElements/Image";
import List from "./StorylineElements/List";
import PromoBoxElement from "./StorylineElements/PromoBoxElement";
import { EmbeddedGalleryInView } from "./StorylineElements/Relation/EmbeddedGallery";
import GroupedRelation from "./StorylineElements/Relation/GroupedRelation";
import SubHeadingElement from "./StorylineElements/SubHeadingElement";
import Table from "./StorylineElements/Table/Table";

const { Body } = Typography;

type StorylineProps = {
  elements: ResolvedCUEElement[];
  path: string;
  typeName: string;
  bodyMaxWidth: number;
  excludeAds?: boolean;
};

const Storyline = ({
  elements,
  path,
  typeName,
  bodyMaxWidth,
  excludeAds = false,
}: StorylineProps) => {
  let paragraphCount = 0;
  let adInsertMax = configGAM.paragraphInsertMax;
  const adInsertPos = configGAM.paragraphInsertPos;
  const adGalleryInsertPos = configGAM.galleryInsertPos;
  return (
    <>
      {elements.map((element, index, allElements) => {
        if (element.type === "paragraph") {
          const bInsertAd =
            !isBrandedContent(typeName) &&
            paragraphCount > 0 &&
            paragraphCount % adInsertPos == 0 &&
            adInsertMax > 0;
          if (bInsertAd) {
            adInsertMax--;
          }
          paragraphCount++;
          return (
            <Fragment key={index}>
              {!excludeAds && bInsertAd && <Ads.AdsMidArticle />}

              <Body>
                <Annotation element={element} />
              </Body>
            </Fragment>
          );
        }
        if (element.type === "sub_head") {
          return <SubHeadingElement key={index} element={element} />;
        }
        if (element.type === "image") {
          return (
            <StorylineImage
              key={index}
              element={element}
              bodyMaxWidth={bodyMaxWidth}
            />
          );
        }
        if (element.type === "embed") {
          return <CustomHtml key={index} element={element} />;
        }
        if (element.type === "gallery_items") {
          let startcount = 0;
          if (paragraphCount >= adInsertPos) {
            startcount = paragraphCount % adInsertPos == 0 ? 2 : 1;
          }
          const numChildren = element.children?.length || 0;
          //less the leftover slides
          paragraphCount += numChildren - (numChildren % adGalleryInsertPos);
          return (
            <Gallery
              key={index}
              element={element}
              path={path}
              startcount={startcount}
            />
          );
        }
        if (element.type === "pull_quote") {
          return (
            <CallOnQuote
              key={index}
              label={element.additionalFields.quoteLabel?.value}
              title={
                element.additionalFields.quote && (
                  <Annotation element={element.additionalFields.quote} />
                )
              }
              description={element.additionalFields.quotee?.value}
            />
          );
        }
        if (element.type === "sponsorship_box") {
          const info = element.children?.find(
            (child): child is ResolveSponsorshipBoxInfoElement =>
              child.type === "sponsorship_box_info",
          );
          const image = element.children?.find(
            (child) => child.type === "image",
          );
          const crop = isCUEImageElement(image)
            ? resolveImageCrop(image, "original")
            : undefined;
          return (
            <SponsorshipBox
              key={index}
              title={info?.additionalFields.title?.value}
              description={info?.additionalFields.description?.value}
              image={crop}
              ctaUrl={info?.additionalFields.cta_url?.value}
              ctaText={info?.additionalFields.cta_text?.value}
            />
          );
        }

        if (
          element.type === "relation" &&
          element.relation?.type === "embeddedGallery" &&
          element.relation.id
        ) {
          return (
            <EmbeddedGalleryInView
              key={index}
              id={element.relation.id}
              path={path}
            />
          );
        }
        if (element.type === "grouped_relation") {
          return <GroupedRelation key={index} elements={element} />;
        }
        if (element.type === "column_images") {
          return (
            <ColumnImage
              key={index}
              element={element}
              bodyMaxWidth={bodyMaxWidth}
            />
          );
        }
        if (element.type === "table") {
          return <Table key={index} element={element} />;
        }
        if (
          element.type === "call_to_action" ||
          element.type === "shop_button"
        ) {
          return (
            <CallToAction
              key={index}
              element={element}
              variant="secondary"
              bodyMaxWidth={bodyMaxWidth}
            />
          );
        }

        if (element.type === "affiliate_declaration") {
          const affiliateChildren =
            element.children &&
            (element.children[0] as ResolvedAffiliateDeclarationElement);
          const affiliateAdditionalFields =
            affiliateChildren && affiliateChildren.additionalFields;

          return (
            !!affiliateAdditionalFields?.declaration.value && (
              <Body key={index}>
                <b>
                  {affiliateAdditionalFields.text?.value ||
                    configAffiliatesDeclaration.text ||
                    AFFILIATE_DECLATION_DEFAULT_TEXT}
                </b>
              </Body>
            )
          );
        }

        if (
          element.type === "list_bulleted" ||
          element.type === "list_numbered"
        ) {
          return <List key={index} element={element} />;
        }

        if (
          element.type === "youtube_video" ||
          element.type === "brightcove_video"
        ) {
          const title = element.additionalFields.title?.value || "";
          const videoUrl = getEmbedUri(element);
          return (
            !!videoUrl && (
              <div key={index} className={styles.embedContainer}>
                <ResponsiveIframe title={title} src={videoUrl} loading="lazy" />
              </div>
            )
          );
        }

        if (element.type === "promo_box") {
          return <PromoBoxElement key={index} element={element} />;
        }

        if (element.type === "product_listing") {
          return (
            <ProductIndex
              key={index}
              element={element}
              allElements={allElements}
            />
          );
        }

        if (
          element.type === "relation" &&
          element.relation?.type === "product" &&
          element.relation.id
        ) {
          return <ProductContent key={index} element={element} />;
        }

        if (element.type === "horizontal_row") {
          return <Divider key={index} variant="story" />;
        }

        if (SHOW_DEBUG_INFO) {
          return (
            <details key={index}>
              <summary>
                <code>Unimplemented block {element.type}</code>
              </summary>
              <pre style={{ overflow: "auto" }}>
                {JSON.stringify(element, null, 2)}
              </pre>
            </details>
          );
        }
        return null;
      })}
    </>
  );
};

export default Storyline;
